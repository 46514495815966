body {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(206, 206, 206);
  background: rgb(8, 8, 8);
  width: 30%;
  margin: auto;
  font-size: 20px;
}

button {
  display: inline;
  margin: 0.5rem 0.5rem;
  font-size: 1rem;
  background-color: #b9b0ab;
  border-radius: 0.5rem;
  padding: 6px 8px;
  font-weight: bold;
  border: none;
}

button:hover {
  cursor: pointer;
  background-color: whitesmoke;
}

a {
  text-decoration: underline;
  color: aliceblue;
}
.footer {
  margin: 1rem 0rem;
  background: rgb(20, 20, 20);
  padding: 1rem 0.5rem 1rem 0.5rem;
}

.footer p {
  font-size: 16px;
}
